exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acercade-js": () => import("./../../../src/pages/acercade.js" /* webpackChunkName: "component---src-pages-acercade-js" */),
  "component---src-pages-actividad-js": () => import("./../../../src/pages/actividad.js" /* webpackChunkName: "component---src-pages-actividad-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrantes-js": () => import("./../../../src/pages/integrantes.js" /* webpackChunkName: "component---src-pages-integrantes-js" */),
  "component---src-pages-investigacion-js": () => import("./../../../src/pages/investigacion.js" /* webpackChunkName: "component---src-pages-investigacion-js" */),
  "component---src-pages-openresearch-js": () => import("./../../../src/pages/openresearch.js" /* webpackChunkName: "component---src-pages-openresearch-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-templates-integrantes-tpl-js": () => import("./../../../src/templates/integrantes-tpl.js" /* webpackChunkName: "component---src-templates-integrantes-tpl-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/template.js" /* webpackChunkName: "component---src-templates-template-js" */)
}

